import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { IAnswer } from '../model/answer.interface';
import { IGetallDTO } from '../model/dto/getall.dto';
import { Lang } from '../model/orm/lang.model';
import { Page } from '../model/orm/page.model';
import { Gal } from '../model/orm/gal.model';
import { Cat } from '../model/orm/cat.model';
import { Item } from '../model/orm/item.model';
import { Currency } from '../model/orm/currency.model';
import { IFilter } from '../model/filter.interface';
import { IItemsGetallDTO } from '../model/dto/items.getall.dto';
import { IFiltersGetDTO } from '../model/dto/filters.get.dto';
import { IItemsGetmoreDTO } from '../model/dto/items.getmore.dto';
import { IItemsMore } from '../model/dto/items.more.dto';
import { Collection } from '../model/orm/collection.model';
import { IWaitlistDTO } from '../common.components/waitlist/waitlist.dto';
import { IGetchunkDTO } from '../model/dto/getchunk.dto';
import { Review } from '../model/orm/review.model';
import { Guide } from '../model/orm/guide.model';
import { ILoginDTO } from '../model/dto/login.dto';
import { User } from '../model/orm/user.model';
import { Country } from '../model/orm/country.model';
import { IRegisterDTO } from '../model/dto/register.dto';
import { IOrderDTO } from '../model/dto/order.dto';
import { IExhaustedItem } from '../model/dto/exhausteditem.dto';
import { IOrderResponseDTO } from '../model/dto/order.response.dto';
import { ILiqpayDTO } from '../model/dto/liqpay.dto';
import { Order } from '../model/orm/order.model';
import { RetturnDTO } from '../model/dto/retturn.dto';
import { IPromocode } from '../model/orm/promocode.interface';
import { Discount } from '../model/orm/discount.model';
import { Setting } from '../model/orm/setting.model';
import {IMonobankDTO} from "../model/dto/monobank.dto";
import {IItemshortDTO} from "../model/dto/itemshort.dto";
import {ICartItemDTO} from "../model/dto/cartitem.dto";
import { CartItem } from '../model/orm/cartitem.model';
import { ICartRecord } from '../model/cartrecord.interface';
import { IAllDataDTO } from "../model/dto/alldata.dto";
import { IAllDataReqDTO } from "../model/dto/alldata.get.req";
import { environment } from '../../environments/environment';

@Injectable()
export class DataService {
    // private root: string = "https://bk.friendsoffashion.com.ua/api";
    private root: string =  environment.URL;
    // private root: string = "http://127.0.0.1:8000/api";

    constructor (private http: HttpClient) {}

    public langsAll(dto: IGetallDTO): Observable<IAnswer<Lang[]>> {return this.sendRequest("POST", "langs/all", dto);}
    public pagesAll(dto: IGetallDTO): Observable<IAnswer<Page[]>> {return this.sendRequest("POST", "pages/all", dto);}
    public galsAll(dto: IGetallDTO): Observable<IAnswer<Gal[]>> {return this.sendRequest("POST", "gals/all", dto);}
    public catsAll(dto: IGetallDTO): Observable<IAnswer<Cat[]>> {return this.sendRequest("POST", "cats/all", dto);}
    public collectionsAll(dto: IGetallDTO): Observable<IAnswer<Collection[]>> {return this.sendRequest("POST", "collections/all", dto);}
    public currenciesAll(dto: IGetallDTO): Observable<IAnswer<Currency[]>> {return this.sendRequest("POST", "currencies/all", dto);}
    public settingsAll(): Observable<IAnswer<Setting[]>> {return this.sendRequest("POST", "settings/all");}
    public itemsByCat(dto: IItemsGetallDTO): Observable<IAnswer<Item[]>> {return this.sendRequest("POST", "items/bycat", dto);}
    public itemsByCollection(dto: IItemsGetallDTO): Observable<IAnswer<Item[]>> {return this.sendRequest("POST", "items/bycollection", dto);}
    public itemsMore(dto: IItemsGetmoreDTO): Observable<IAnswer<IItemsMore>> {return this.sendRequest("POST", "items/more", dto);}
    public itemsHits (dto: IGetallDTO): Observable<IAnswer<Item[]>> {return this.sendRequest("POST", "items/hits", dto);}
    public itemsSearch (dto: IItemsGetallDTO): Observable<IAnswer<Item[]>> {return this.sendRequest("POST", "items/search", dto);}
    public itemBySlug (slug: string): Observable<IAnswer<Item>> {return this.sendRequest("POST", `items/byslug/${slug}`);}
    public filtersByCat (dto: IFiltersGetDTO): Observable<IAnswer<IFilter>> {return this.sendRequest("POST", `filters/bycat`, dto);}
    public filtersBySearch (dto: IFiltersGetDTO): Observable<IAnswer<IFilter>> {return this.sendRequest("POST", `filters/bysearch`, dto);}
    public filtersByCollection (dto: IFiltersGetDTO): Observable<IAnswer<IFilter>> {return this.sendRequest("POST", `filters/bycollection`, dto);}
    public waitsCreate(dto: IWaitlistDTO): Observable<IAnswer<void>> {return this.sendRequest("POST", "waits/create", dto);}
    public reviewsChunkByItem(itemId: number, dto: IGetchunkDTO): Observable<IAnswer<Review[]>> {return this.sendRequest("POST", `reviews/chunkbyitem/${itemId}`, dto);}
    public reviewsSend(review: Review): Observable<IAnswer<void>> {return this.sendRequest("POST", "reviews/create", review);}
    public guidesAll(dto: IGetallDTO): Observable<IAnswer<Guide[]>> {return this.sendRequest("POST", "guides/all", dto);}
    public countriesAll(dto: IGetallDTO): Observable<IAnswer<Country[]>> {return this.sendRequest("POST", "countries/all", dto);}
    public userLogin(dto: ILoginDTO): Observable<IAnswer<User>> {return this.sendRequest("POST", "users/login", dto);}
    public userRegister(dto: IRegisterDTO): Observable<IAnswer<User>> {return this.sendRequest("POST", "users/register", dto);}
    public userNotifyAboutRegistration(dto: IRegisterDTO): Observable<IAnswer<void>> {return this.sendRequest("POST", "users/notify/register", dto);}
    public userRecover(email: string): Observable<IAnswer<void>> {return this.sendRequest("POST", "users/recover", {email});}
    public userUpdate(user: User): Observable<IAnswer<void>> {return this.sendRequest("POST", "users/update", user);}
    public orderCreate(dto: IOrderDTO): Observable<IAnswer<IOrderResponseDTO>> {return this.sendRequest("POST", "orders/create", dto);}
    public orderLiqpayBuild(id: number): Observable<IAnswer<ILiqpayDTO>> {return this.sendRequest("POST", `orders/liqpay/build/${id}`);}
    public orderMonobankBuild(id: number): Observable<IAnswer<IMonobankDTO>> {return this.sendRequest("POST", `orders/monobank/build/${id}`);}
    public orderNotifyAboutCreation(id: number): Observable<IAnswer<void>> {return this.sendRequest("POST", `orders/notify/created/${id}`);}
    public orderToMoysklad(id: number): Observable<IAnswer<void>> {return this.sendRequest("POST", `orders/tomoysklad/${id}`);}
    public orderHistory(userId: number): Observable<IAnswer<Order[]>> {return this.sendRequest("POST", `orders/history/${userId}`);}
    public orderRetturn(dto: RetturnDTO): Observable<IAnswer<number>> {return this.sendRequest("POST", "orders/retturn", dto);}
    public orderNotifyAboutReturn(id: number): Observable<IAnswer<void>> {return this.sendRequest("POST", `orders/notify/retturn/${id}`);}
    public promocodeApply(code: string): Observable<IAnswer<IPromocode>> {return this.sendRequest("POST", "promocodes/apply", {code});}
    public discount(catId = null): Observable<IAnswer<Discount>> {return this.sendRequest("POST", "discounts/timer", {catId});}
    public getWaitList(string: string): Observable<IAnswer<IItemshortDTO>> {return this.sendRequest("POST", `itemshort`, string);}
    public updateCartReserve(cartUserId: number, cartItems: object): Observable<IAnswer<Array<ICartRecord>>> {return this.sendRequest("POST", "cart/updateReserve", {cartUserId: cartUserId, cartItems: cartItems});}
    public getCartReserves(cartUserId: number): Observable<IAnswer<Array<ICartRecord>>> {return this.sendRequest("POST", "cart/getCart", {cartUserId});}
    public allData(dto: IAllDataReqDTO): Observable<IAnswer<IAllDataDTO>> {return this.sendRequest("POST", "alldata", {dto});}
    public createReserve(data: object, cartUserId: number): Observable<any> { return this.sendRequest('POST', 'cart/reserve', { cartUserId, items: data })};
    public deleteReserve(cartUserId: number): Observable<any> { return this.sendRequest('DELETE', 'cart/reserve', { cartUserId})};
    public getPreHeaderData(): Observable<any> { return this.sendRequest('GET', 'preheaders')};
    public getWelcomeModalData(): Observable<any> { return this.sendRequest('GET', 'modals')};
    public getSaleSettingsData(): Observable<any> { return this.sendRequest('GET', 'sale-settings')};
    public collectionStart(phone: string): Observable<any> { return this.sendRequest('POST', 'collections/waits', { phone })};

    private sendRequest (method: string, url: string, body: Object = {}, authNeeded: boolean = false, withProgress: boolean = false): Observable<any> | null {
        let headers: HttpHeaders | null = null;

        if (authNeeded) {
            //headers = new HttpHeaders({token: this.authData.token})
        }

        if (method === "GET") {
            return this.http.get (`${this.root}/${url}`, {headers});
        } else if (method === "POST") {
            if (withProgress) {
                return this.http.post (`${this.root}/${url}`, body, {headers, observe: "events", reportProgress: true});
            } else {
                return this.http.post (`${this.root}/${url}`, body, {headers});
            }
        } else if (method === "DELETE") {
            let options: Object = {body, headers};
            return this.http.delete(`${this.root}/${url}`, options);
        }

        return null;
    }
}
