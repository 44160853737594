import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Item } from 'src/app/model/orm/item.model';
import { ISize } from 'src/app/model/orm/size.interface';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { CollectionStartService } from './collection-start.service';

@Component({
  selector: "the-collection-start",
  templateUrl: "./collection-start.component.html",
  styleUrls: ["./collection-start.component.scss"]
})
export class CollectionStartComponent {
  // @Input() item: Item;
  @Input() active: boolean;
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
  // public sizes: ISize[] = [];
  public sent: boolean = false;
  public sending: boolean = false;
  public phone: string = "";
  // public subscribe: boolean = true;
  public isValidFlg:boolean = true;

  constructor(
    private appService: AppService,
    private collectionStartService: CollectionStartService
  ) {}

  get currentLang(): Lang {return this.appService.currentLang.value;}

  public close() {
    this.activeChange.emit(false);
  }

  public onClick(event: MouseEvent | TouchEvent): void {
    let target: HTMLElement = event.target as HTMLElement;
    target.classList.contains("collection-start") ? this.close() : null;
  }

  public async send(): Promise<void> {
    try {
      this.phone = this.phone.trim();

      if (this.phone.length) {
        this.sending = true;
        await this.collectionStartService.sendJoin(this.phone);
        this.sent = true;
        this.sending = false;
      }
    } catch (err) {
      this.appService.showNotification(err, "error");
    }
  }

  public validatePhoneNo(event, field) {
    let phoneNumDigits = field.value.replace(/\D/g, '');

    if (phoneNumDigits.substring(0, 2) === '38') {
      phoneNumDigits = phoneNumDigits.substring(2);
    }

    this.isValidFlg = (phoneNumDigits.length === 0 || phoneNumDigits.length === 10);

    let formattedNumber = phoneNumDigits;

    if (phoneNumDigits.length <= 3) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3);
    }
    if (phoneNumDigits.length <= 6 && phoneNumDigits.length > 3) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6);
    }
    if (phoneNumDigits.length <= 8 && phoneNumDigits.length > 6) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8);
    }
    if (phoneNumDigits.length > 8) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8) + ' ' + phoneNumDigits.substring(8);
    }

    if (formattedNumber.length > 17) {
      field.value = formattedNumber.slice(0, 17);
      return;
    } else {
      field.value = formattedNumber;
    }
  }
}
