import { NgModule } from '@angular/core';

import { AppService } from './app.service';
import { DataService } from './data.service';
import { StatisticsGoogleService } from './statistics-google.service';
import { StatisticsFacebookService } from './statistics-facebook.service';
import { StatisticsConverterService } from './statistics-converter.service';
import { LangsRepository } from './repositories/langs.repository';
import { PagesRepository } from './repositories/pages.repository';
import { GalsRepository } from './repositories/gals.repository';
import { CatsRepository } from './repositories/cats.repository';
import { ItemsByCatRepository } from './repositories/itemsbycat.repository';
import { CurrenciesRepository } from './repositories/currencies.repository';
import { ItemsHitsRepository } from './repositories/itemshits.repository';
import { FilterByCatRepository } from './repositories/filterbycat.repository';
import { FilterBySearchRepository } from './repositories/filterbysearch.repository';
import { ItemsSearchRepository } from './repositories/itemssearch.repository';
import { ItemsBySlugRepository } from './repositories/itemsbyslug.repository';
import { ItemsMoreRepository } from './repositories/itemsmore.repository';
import { CollectionsRepository } from './repositories/collections.repository';
import { ItemsService } from './items.service';
import { ItemsByCollectionRepository } from './repositories/itemsbycollection.repository';
import { FilterByCollectionRepository } from './repositories/filterbycollection.repository';
import { ReviewsByItemRepository } from './repositories/reviewsbyitem.repository';
import { GuidesRepository } from './repositories/guides.repository';
import { CartService } from './cart.service';
import { WishlistService } from './wishlist.service';
import { AuthService } from './auth.service';
import { CountriesRepository } from './repositories/countries.repository';
import { OrdersRepository } from './repositories/orders.repository';
import { SettingsRepository } from './repositories/settings.repository';
import { DiscountRepository } from './repositories/discount.repository';
import { AnalyticsService } from './analytics.service';
import { AlldataRepository } from './repositories/alldata.repository';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        AppService,
        DataService,
        ItemsService,
        CartService,
        WishlistService,
        AuthService,
        LangsRepository,
        PagesRepository,
        GalsRepository,
        CatsRepository,
        CollectionsRepository,
        CurrenciesRepository,
        ItemsByCatRepository,
        ItemsByCollectionRepository,
        ItemsHitsRepository,
        ItemsSearchRepository,
        ItemsBySlugRepository,
        ItemsMoreRepository,
        FilterByCatRepository,
        FilterBySearchRepository,
        FilterByCollectionRepository,
        ReviewsByItemRepository,
        GuidesRepository,
        CountriesRepository,
        OrdersRepository,
        SettingsRepository,
        StatisticsGoogleService,
        StatisticsFacebookService,
        StatisticsConverterService,
        DiscountRepository,
        AlldataRepository,
        AnalyticsService
    ],
})
export class ServicesModule {}
