import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var gtag: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public trackEvent(eventName: string, eventDetails: string) {
    // if (environment.production) {
      gtag('event', eventName, {
        // 'event_category': eventCategory,
        'event_label': eventName,
        'value': eventDetails
      });
    // }
  }
}
