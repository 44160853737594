import { Component } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { Cart } from 'src/app/model/cart';
import { Item } from 'src/app/model/orm/item.model';
import { Currency } from 'src/app/model/orm/currency.model';
import { Lang } from 'src/app/model/orm/lang.model';
import { ICartRecord } from 'src/app/model/cartrecord.interface';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
    selector: "cart-panel",
    templateUrl: "./cartpanel.component.html",
    styleUrls: ["./cartpanel.component.scss"]
})
export class CartPanelComponent {
    public anyCheckoutCountError = false;
    constructor(
        private appService: AppService,
        private cartService: CartService,
        private dataService: DataService,
        private router: Router,
        private analyticsService: AnalyticsService
    ) {}

    get active(): boolean {return this.appService.cartpanelActive;}
    set active(v: boolean) {this.appService.cartpanelActive = v;}
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get currentLang(): Lang {return this.appService.currentLang.value;}
    get cart(): Cart {return this.cartService.cart;}
    get cartQ(): number {return this.cartService.q;}
    get cartS(): number {return this.cartService.sum;}
    get cartRelated(): Item[] {return this.cartService.related;}
    set mobmenuActive(v: boolean) {this.appService.mobmenuActive = v;}
    get bagLabel(): string {return `${this.currentLang.s['cart']['bag']} (${this.cartService.bagCost} ${this.currentCurrency.name})`;}

    public getItemImg(item: Item): string {
        return item.pictures[0].img_s ? `url(/images/${item.pictures[0].img_s})` : `url(/images/noimage.jpg)`;
    }

    public getItemPrice(item: Item): number {
        return Math.ceil(item.price * this.currentCurrency.rate);
    }

    public decrease(r: ICartRecord): void {
        this.cartService.decreaseRecord(r);
    }

    public increase(r: ICartRecord): void {
        this.cartService.increaseRecord(r);
    }

    public remove(r: ICartRecord): void {
        this.cartService.removeRecord(r);
    }

    public saveCart(): void {
        this.cartService.save();
    }

    public onCheckout(): void {
      this.analyticsService.trackEvent('booking_cta', '');
      const { records } = this.cart;
      const data = [];

      [...records].forEach(item => {
        data.push({
          item_id: item.item.id,
          q: item.q,
          id: item.id,
          size: {
            id: item.size.id
          }
        });
      });

      this.dataService.createReserve(data, this.cartService.cartUserId).subscribe(
        response => {
          this.anyCheckoutCountError = false;
          const timeNow = new Date();

          this.active = false;
          this.mobmenuActive = false;

          this.cart.records.forEach(record => {
            record.errorBeforeCheckout = false;
            record.errorBeforeCheckoutQuantity = null;
          });

          this.cart.reserve = [...this.cart.records];
          this.cart.newTimer = timeNow.getTime() + 600000;

          this.router.navigate([`${this.currentLang.slug}/shop/checkout-continue`]);
        },
        error => {
          this.anyCheckoutCountError = true;

          this.cart.records.forEach(record => {
            record.errorBeforeCheckout = false;
            record.errorBeforeCheckoutQuantity = null;
          });

          error.error.forEach(item => {
            this.cart.records.forEach(record => {
              if (record.id === item.id) {
                record.errorBeforeCheckout = true;
                record.errorBeforeCheckoutQuantity = item.max_quantity;
              }
            });
          });
        });
    }
}
