<div class="compact padded" [class.discounted]="item && item.price != item.original_price">
  <div id="breadcrumbs" *ngIf="catReady">
    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>
    <span><a routerLink="/{{currentLang.slug}}/shop/{{cat.slug}}">{{cat.name[currentLang.slug]}}</a></span>
  </div>

  <ng-container *ngIf="item">
    <gal-itempictures-popup [pictures]="item.pictures" (closed)="onGalItemPicturesPopupClosed()" (pictureChanged)="galOnPage.changeCurrentI($event)" class="gal-popup" [shown]="galItemPicturesPopupShown" #galPopUp></gal-itempictures-popup>
    <div class="item">
      <div class="item-left" [class.transparent]="!itemReady">
        <div class="item-images">
          <wishlist-toggle [item]="item" [isOnSingleItem]="true"></wishlist-toggle>
          <gal-itempictures [pictures]="item.pictures" (pictureChanged)="galPopUp.changeCurrentI($event)" [isShowPreorderBudget]="isShowPreorderBudget" (pictureClicked)="showGalItemPicturesPopup($event)" #galOnPage></gal-itempictures>
        </div>
      </div>
      <div class="item-right">
        <div class="item-head">
          <div class="item-nameprice">
            <h1>{{item.h1[currentLang.slug] || item.name[currentLang.slug]}}</h1>
            <div class="item-price">
              <ng-container *ngIf="item.price != item.original_price">
                <span class="original_price">{{item.original_price}} {{currentCurrency.name}}</span>
              </ng-container>
              {{price}} {{currentCurrency.name}}</div>
          </div>
        </div>
        <!--
        <div class="item-rating">
            <ng-container *ngIf="item.reviewsQ">
                <div class="ir-left"><stars [rate]="item.reviewsRate"></stars></div>
                <div class="ir-right" (click)="scrollToReviews();">{{currentLang.s['item']['reviews']}}: {{item.reviewsQ}}</div>
            </ng-container>
        </div>
        -->
        <div class="item-colors" *ngIf="item.variations.length">
          <div class="item-colors-head">{{currentLang.s['item']['color']}} - {{item.color.name[currentLang.slug]}}</div>
          <div class="item-colors-patches">
            <div
              class="item-color"
              *ngFor="let v of item.variations;"
              [class.active]="v.current"
            >
              <div class="item-patch" [style.background-color]="v.color.code" routerLink="/{{currentLang.slug}}/shop/{{catslug}}/{{v.slug}}"></div>
            </div>
          </div>
        </div>
        <div class="item-sizes-radio" *ngIf="selectedSize">
          <div class="item-size-radio" *ngFor="let s of item.sizes" [class.active]="selectedSize == s" [class.sold-out]="s.q <= 0" (click)="q = 1; selectedSize = s; isShowPreorderBudget = !!s.preorder">
            <div class="item-size-radio-name">{{s.name}}</div>
            <div class="item-size-radio-status">{{(s.q <= 0 ) ? currentLang.s['item']['notinstock'] : ((s.q == 1) ? currentLang.s['item']['lastsize'] : '&nbsp;')}}</div>
          </div>
        </div>
        <div class="item-sizeguide" *ngIf="false" (click)="sizeguidesActive = true;">{{currentLang.s['item']['sizeguide']}}</div>

        <div class="item-add" *ngIf="!cart_enabled && item.catslug === 'leather-edition'; else itemAdd">
          <button (click)="openStartCollectionModal()">
            Повідомити про запуск колекції
          </button>

          <div class="new-collection">
            Це виріб з нової колекції, яка буде доступна до покупки з 12.10. Натисніть кнопку, щоб ми повідомили вам про запуск колекції
          </div>
        </div>

        <!--        <div #itemAdd *ngIf="cart_enabled || availableCartId.includes(cart.cartUserId)">-->
        <ng-template #itemAdd>
          <div class="item-add" *ngIf="selectedSize" [class.onscreen]="isItemAllScrolledIntoView">
            <button [disabled]="q === 0" *ngIf="selectedSize.q > 0" (click)="toCart();">{{currentLang.s['item']['tocart']}}</button>
            <button [disabled]="q === 0" *ngIf="selectedSize.q <= 0 && selectedSize.preorder" (click)="toCart();">{{currentLang.s['waitlist']['preorder_button']}}</button>
            <button *ngIf="selectedSize.q <= 0 && !selectedSize.preorder" (click)="toWaitList()">
              {{checkStatus == 'order' ? currentLang.s['item']['towaitlist'] : currentLang.s['item']['towaitlist']}}
            </button>
            <div class="item-exhausted" [class.active]="exhausted">{{currentLang.s['item']['exhausted']}}</div>
          </div>
        </ng-template>

        <costperwear-slider *ngIf="item.price > 0"
          [min]="1"
          [max]="100"
          [price]="item.price"
        ></costperwear-slider>
        <div class="item-about">
          <div class="ia-buttons">
            <div class="ia-button" [class.active]="detailsMode === 0" (click)="detailsMode = 0;">{{currentLang.s['item']['details1']}}</div>
            <div class="ia-button" [class.active]="detailsMode === 3" (click)="detailsMode = 3;">{{currentLang.s['item']['details4']}}</div>
            <div class="ia-button" [class.active]="detailsMode === 2" (click)="detailsMode = 2;">{{currentLang.s['item']['details3']}}</div>
            <div class="ia-button" [class.active]="detailsMode === 1" (click)="detailsMode = 1;" *ngIf="page">{{currentLang.s['item']['details2']}}</div>
          </div>
          <div class="ia-text" [innerHTML]="detailsMode === 0 ? item.content[currentLang.slug] : (detailsMode === 1 ? page.content[currentLang.slug] : (detailsMode === 2 ? (item.obmery ? item.obmery[currentLang.slug] : '') : (item.material ? item.material[currentLang.slug] : '')))"></div>

          <div class="details-contacts">
            <h3 class="details-contacts__h3">{{currentLang.s['item']['contacts-header']}}</h3>
            <ul class="details-contacts__list">
              <li class="details-contacts__telegram">
                <a [href]="currentLang.s['contacts']['telegram'] | nosanitize: 'url'">
                  <span>Telegram</span>
                </a>
              </li>
              <li class="details-contacts__viber">
                <a [href]="currentLang.s['contacts']['viber'] | nosanitize: 'url'">
                  <span>Viber</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- pair with -->
    <ng-container *ngIf="item.related.length">
      <h2 class="centered">{{currentLang.s['item']['pairwith']}}</h2>
      <gal-pair [picture]="item.pictures ? item.pictures[0] : null" [items]="item.related" [relatedpos]="item.relatedpos"></gal-pair>
    </ng-container>
    <!-- more in -->
    <ng-container *ngIf="moreByColor.length || moreByCat.length">
      <h2 class="centered">{{currentLang.s['item']['morein']}}</h2>
      <div class="mi-switches">
        <div class="mi-switch" *ngIf="moreByCat.length" [class.active]="moreinMode === 'cat'" (click)="moreinMode = 'cat';">{{currentLang.s['item']['moreincat']}}</div>
        <div class="mi-switch" *ngIf="moreByColor.length" [class.active]="moreinMode === 'color'" (click)="moreinMode = 'color';">{{currentLang.s['item']['moreincolor']}}</div>
      </div>
      <div class="mi" *ngIf="moreByCat.length" [class.active]="moreinMode === 'cat'">
        <gal-items [items]="moreByCat"></gal-items>
      </div>
      <div class="mi" *ngIf="moreByColor.length" [class.active]="moreinMode === 'color'">
        <gal-items [items]="moreByColor"></gal-items>
      </div>
    </ng-container>
    <!-- reviews -->
    <!--
    <br>
    <a name="reviews" #reviews></a>
    <h2 class="centered">{{currentLang.s['reviews']['reviews']}}</h2>
    <item-reviews [item]="item" [formActive]="reviewsFormActive"></item-reviews>
    -->
  </ng-container>
  <div class="mindset">
    <div class="mindset-top">{{currentLang.s['item']['mindset_top']}}</div>
    <div class="mindset-content">{{currentLang.s['item']['mindset_content']}}</div>
  </div>
</div>

<the-waitlist *ngIf="item" [item]="item" [sizeId]="selectedSize ? selectedSize.id : null" [colorId]="item.color.id ? item.color.id: null" [(active)]="waitlistActive"></the-waitlist>
<the-collection-start *ngIf="item" [(active)]="collectionStartActive"></the-collection-start>
<the-nosizemodal *ngIf="!nosizemodalShowed" [(active)]="nosizemodalActive" [noizeModalClose]="noizeModalClose"></the-nosizemodal>
<size-guides [(active)]="sizeguidesActive"></size-guides>
