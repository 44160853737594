<footer>
    <div id="f-content">
        <div id="f-menu">
            <div class="fm-section">
                <div class="fms-head">{{currentLang.s['common']['help']}}</div>
                <nav>
                    <div *ngFor="let p of pagesHelp"><a [routerLink]="'/'+currentLang.slug+'/'+p.slug">{{p.name[currentLang.slug]}}</a></div>
                </nav>
            </div>
            <div class="fm-section">
                <div class="fms-head">{{currentLang.s['common']['more']}}</div>
                <nav>
                    <div *ngFor="let p of pagesMore"><a [routerLink]="'/'+currentLang.slug+'/'+p.slug">{{p.name[currentLang.slug]}}</a></div>
                </nav>
            </div>
        </div>

        <div id="f-bottom">
            <div id="fb-right">
                <div id="f-socials">
                    <a [href]="currentLang.s['contacts']['viber'] | nosanitize: 'url'"><span id="f-viber"></span></a>
                    <a [href]="currentLang.s['contacts']['telegram'] | nosanitize: 'url'"><span id="f-telegram"></span></a>
                    <a [href]="currentLang.s['contacts']['instagram'] | nosanitize: 'url'" target="_blank"><span id="f-instagram"></span></a>
                    <a href="mailto:{{currentLang.s['contacts']['email']}}"><span id="f-email"></span></a>
                </div>
            </div>
            <div id="fb-left">
                <div *ngIf="false" id="f-langs">
                    <div id="f-langsmark"><span></span></div>
                    <ul>
                        <li *ngFor="let l of langs"><a [class.active]="l.id === currentLang.id" [routerLink]="getLangLink(l.slug)">{{l.title}}</a></li>
                    </ul>
                </div>
                <div id="f-currencies" *ngIf="false">
                    <div id="f-currenciesmark"><span></span></div>
                    <ul>
                        <li *ngFor="let c of currencies" (click)="setCurrency(c);"><span [class.active]="c.id === currentCurrency.id">{{c.name}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
