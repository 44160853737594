import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';

@Injectable()
export class CollectionStartService {
  constructor(private dataService: DataService) {}

  public sendJoin(phone): Promise<void> {
    return new Promise((resolve, reject) => {
      this.dataService.collectionStart(phone).subscribe(response => {
        if (response) {
          resolve();
        } else {
          reject(response.statusCode+": "+response.error);
        }
      }, error => {
        reject(error.message);
      });
    });
  }
}
